import React, { ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'
import { SecureRoute, LoginCallback } from '@okta/okta-react'
import { ROUTES } from './constants'
import { Header, Sidebar } from 'components'
import { Login, Logout, CaseResponse } from 'pages'
import { UserData } from 'hooks'
import { useOktaAuth } from '@okta/okta-react'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'

export const AppRoutes = () => {
  return (
    <Switch>
      {ROUTES.map(({ path, requiredRoles, Component }) => (
        <SecureRoute
          exact
          path={path}
          key={path}
          component={() => (
            <>
              <Header />
              <Sidebar />
              <ErrorBoundary>
                <WrappedRoledComponent Component={Component} requiredRoles={requiredRoles} />
              </ErrorBoundary>
            </>
          )}></SecureRoute>
      ))}
      <Route path="/case-response/:externalId" component={CaseResponse} />
      <Route path="/login/callback" component={LoginCallback} />
      <Route path="/logout" component={Logout} />
      <Route path="/" exact component={Login} />
      <Route path="/login" component={Login} />
    </Switch>
  )
}

const WrappedRoledComponent = ({
  Component,
  requiredRoles
}: {
  Component: () => ReactElement
  requiredRoles: string[]
}) => {
  const user = JSON.parse(sessionStorage.getItem('userData')) as UserData
  const { oktaAuth } = useOktaAuth()
  if (!user) return <Component />
  if (requiredRoles.includes(user.role as string)) {
    return <Component />
  }
  oktaAuth.signOut()
}
