import React from 'react'
import { Box, SxProps, Typography } from '@mui/material'
import { ErrorOutline, Inbox } from '@mui/icons-material'

export const UnexpectedErrorMessage = ({ sx }: { sx?: SxProps }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingY: 10,
      ...sx
    }}>
    <ErrorOutline sx={{ color: 'secondary', fontSize: 40 }} />
    <Typography variant="body1" sx={{ marginLeft: 2 }}>
      An unexpected error has occurred.
    </Typography>
  </Box>
)

export const NoDataFoundMessage = ({ sx }: { sx?: SxProps }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingY: 10,
      ...sx
    }}>
    <Inbox sx={{ color: 'secondary', fontSize: 40 }} />
    <Typography variant="body1" sx={{ marginLeft: 2 }}>
      No data found.
    </Typography>
  </Box>
)

export const NoDataFoundMessageWithText = ({ text, sx }: { text: string; sx?: SxProps }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingY: 10,
      ...sx
    }}>
    <Inbox sx={{ color: 'secondary', fontSize: 40 }} />
    <Typography variant="body1" sx={{ marginLeft: 2 }}>
      {text}
    </Typography>
  </Box>
)
