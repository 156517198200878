import React, { useEffect, useState } from 'react'
import { Loading, Logo, NoDataFoundMessageWithText, StyledAppBar, TableContainer } from 'components'
import {
  Box,
  Typography,
  List,
  ListItem,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Button
} from '@mui/material'
import { FiberManualRecordRounded } from '@mui/icons-material'
import { useLocation, useParams } from 'react-router-dom'
import { runtimeConfig } from '../../../config'

interface PageData {
  loading: boolean
  error: boolean
  message?: string
  case?: {
    rows: Record<string, string>[]
    dealerName: string
    createdAt: string
    expiresAt: string
  }
}

interface NotifyNewCaseAuthResponse {
  externalId: string
  requestedServiceCode: string
  vin: string
  vehicleYear?: number
  vehicleColor: string
  vehicleMake: string
  vehicleModel: string
  firstName: string
  lastName: string
  phone: string
  street?: string
  streetNumber?: string
  city?: string
  state?: string
  country?: string
  zipcode?: string
  latitude?: number
  longitude?: number
  distance: string
  dealerName: string
  createdAt: string
  expiresAt: string
}

const baseURL = runtimeConfig.RAZZLE_DLS_API_URL

const getRows = (data: NotifyNewCaseAuthResponse): Record<string, string>[] => {
  return [
    { key: 'Case Number', value: data.externalId },
    { key: 'Service Requested', value: data.requestedServiceCode },
    { key: 'Customer Name', value: `${data.firstName} ${data.lastName}` },
    { key: 'Customer Phone', value: data.phone },
    { key: 'Address', value: `${data.streetNumber || ''} ${data.street || ''}` },
    { key: 'City', value: data.city || '' },
    { key: 'State', value: data.state || '' },
    { key: 'Zip', value: data.zipcode || '' },
    { key: 'Distance', value: data.distance },
    { key: 'Latitude / Longitude', value: `${data.latitude || ''}/${data.longitude || ''}` },
    { key: 'Year', value: data.vehicleYear?.toString() || '' },
    { key: 'Make', value: data.vehicleMake },
    { key: 'Model', value: data.vehicleModel },
    { key: 'Color', value: data.vehicleColor },
    { key: 'VIN', value: data.vin }
  ]
}

const getButtonText = (answer: string): string => {
  switch (answer) {
    case '1':
      return 'Accept ETA <= 45 Mins'
    case '2':
      return 'Accept ETA <= 60 Mins'
    case '3':
      return 'Accept ETA <= 90 Mins'
    case '4':
      return 'Declined - Closed'
    case '5':
      return 'Declined - Vehicle NA'
    case '6':
      return 'Declined - On other call'
    case '7':
      return 'Declined - Out of range'
    case '8':
      return 'Declined - Tech not available'
    case '9':
      return 'Declined - Weather'
    default:
      return 'Unknown'
  }
}

const AnswerButton = ({
  text,
  onClick,
  primary = false
}: {
  text: string
  onClick: () => void
  primary?: boolean
}) => {
  return (
    <>
      {primary && (
        <Button
          sx={{
            px: 2,
            borderRadius: 0
          }}
          color="secondary"
          variant="contained"
          onClick={onClick}>
          {text}
        </Button>
      )}
      {!primary && (
        <Button variant="contained" onClick={onClick}>
          {text}
        </Button>
      )}
    </>
  )
}

const ButtonSet = ({
  receivedAnswer,
  token,
  externalId
}: {
  receivedAnswer: string
  token: string
  externalId: string
}) => {
  const [data, setData] = useState({
    loading: false,
    answered: false,
    answer: undefined
  })

  const sendAnswer = async () => {
    const url = `${baseURL}/dispatch-case/notify-new-case-callback?token=${token}&answer=${data.answer}&externalId=${externalId}`

    const response = await fetch(url)

    setData({
      loading: false,
      answered: response.status === 200,
      answer: data.answer
    })
  }

  useEffect(() => {
    if (!data.loading || !data.answer || data.answered) return

    sendAnswer()
  }, [data])

  return (
    <>
      {data.loading && <Loading />}
      {data.answered && !data.loading && (
        <Typography fontSize={20} sx={{ mt: 3, mb: 1 }}>
          Thank you for your response!
        </Typography>
      )}
      {!data.answered && !data.loading && (
        <>
          <Typography fontSize={20} sx={{ mb: 1 }}>
            Please confirm your answer
          </Typography>
          <AnswerButton
            text={getButtonText(receivedAnswer)}
            onClick={() =>
              setData({
                loading: true,
                answered: false,
                answer: receivedAnswer
              })
            }
            primary
          />
          <Typography fontSize={20} sx={{ mt: 3, mb: 1 }}>
            Otherwise, change your answer:
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: 1
            }}>
            <AnswerButton
              text={getButtonText('1')}
              onClick={() =>
                setData({
                  loading: true,
                  answered: false,
                  answer: '1'
                })
              }
            />
            <AnswerButton
              text={getButtonText('2')}
              onClick={() =>
                setData({
                  loading: true,
                  answered: false,
                  answer: '2'
                })
              }
            />
            <AnswerButton
              text={getButtonText('3')}
              onClick={() =>
                setData({
                  loading: true,
                  answered: false,
                  answer: '3'
                })
              }
            />
            <AnswerButton
              text={getButtonText('4')}
              onClick={() =>
                setData({
                  loading: true,
                  answered: false,
                  answer: '4'
                })
              }
            />
            <AnswerButton
              text={getButtonText('5')}
              onClick={() =>
                setData({
                  loading: true,
                  answered: false,
                  answer: '5'
                })
              }
            />
            <AnswerButton
              text={getButtonText('6')}
              onClick={() =>
                setData({
                  loading: true,
                  answered: false,
                  answer: '6'
                })
              }
            />
            <AnswerButton
              text={getButtonText('7')}
              onClick={() =>
                setData({
                  loading: true,
                  answered: false,
                  answer: '7'
                })
              }
            />
            <AnswerButton
              text={getButtonText('8')}
              onClick={() =>
                setData({
                  loading: true,
                  answered: false,
                  answer: '8'
                })
              }
            />
            <AnswerButton
              text={getButtonText('9')}
              onClick={() =>
                setData({
                  loading: true,
                  answered: false,
                  answer: '9'
                })
              }
            />
          </Box>
        </>
      )}
    </>
  )
}

const PageContent = ({
  rows,
  dealerName,
  createdAt,
  expiresAt,
  answer,
  externalId,
  token
}: {
  rows: Record<string, string>[]
  dealerName: string
  createdAt: string
  expiresAt: string
  answer: string
  externalId: string
  token: string
}) => {
  return (
    <Box>
      <StyledAppBar component="div" position="static" elevation={0} sx={{ px: 2 }}>
        <Logo />
      </StyledAppBar>
      <Box
        sx={{
          pt: 13,
          px: 5,
          height: '100vh',
          backgroundColor: 'secondary.light'
        }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Dealer Light Service Dispatch Opportunity
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          This is a Lexus Dealer Light Service (DLS) opportunity received from a guest requesting
          service from your dealership {dealerName} at <strong>{createdAt}.</strong> Please select
          one of the options at the bottom of this request to accept or decline this DLS
          opportunity.
          <strong>
            <List>
              <ListItem sx={{ px: 0 }}>
                <FiberManualRecordRounded sx={{ fontSize: 14, mr: 1 }} />
                If accepting, select the appropriate option to indicate appropriate ETA that will be
                communicated to the guest.
              </ListItem>
              <ListItem sx={{ px: 0 }}>
                <FiberManualRecordRounded sx={{ fontSize: 14, mr: 1 }} />
                If declining, select the appropriate option to indicate reason.
              </ListItem>
            </List>
            This service opportunity will expire at {expiresAt}.
          </strong>
        </Typography>

        <TableContainer>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 4, height: '50%' }}>
            <Box>
              <Typography fontSize={20} sx={{ mb: 1 }}>
                Dealer Light Service Request
              </Typography>
              <Table size="small">
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.key}>
                      <TableCell
                        component="th"
                        sx={{ fontWeight: 'bold', border: '1px solid' }}
                        scope="row">
                        {row.key}:
                      </TableCell>
                      <TableCell sx={{ border: '1px solid' }} align="left">
                        {row.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box flex={1}>
              <ButtonSet receivedAnswer={answer} externalId={externalId} token={token} />
            </Box>
          </Box>
        </TableContainer>
      </Box>
    </Box>
  )
}

export const CaseResponse = () => {
  const [data, setData] = useState<PageData>({
    loading: true,
    error: false
  })

  const { externalId } = useParams()
  const { search } = useLocation()

  const query = new URLSearchParams(search)
  const token = encodeURIComponent(query.get('token'))
  const answer = query.get('answer')

  const shouldRender = externalId && token && answer

  const auth = async (params: { externalId: string; token: string }): Promise<void> => {
    const response = await fetch(
      `${baseURL}/dispatch-case/${params.externalId}?token=${params.token}`
    )
    const data = await response.json()

    const error = response.status !== 200
    const message = error ? data.message : undefined
    const caseData = !error
      ? {
          rows: getRows(data),
          dealerName: data.dealerName,
          createdAt: data.createdAt,
          expiresAt: data.expiresAt
        }
      : undefined

    setData({
      loading: false,
      error,
      message,
      case: caseData
    })
  }

  useEffect(() => {
    if (!data.loading) return

    auth({ externalId, token })
  }, [])

  return (
    <>
      {!shouldRender && <NoDataFoundMessageWithText text="Invalid Request" />}
      {shouldRender && data.loading && !data.case && <Loading />}
      {shouldRender && !data.loading && data.error && (
        <NoDataFoundMessageWithText text={data.message} />
      )}
      {shouldRender && !data.loading && !data.error && (
        <PageContent
          rows={data.case.rows}
          dealerName={data.case.dealerName}
          createdAt={data.case.createdAt}
          expiresAt={data.case.expiresAt}
          answer={answer}
          externalId={externalId}
          token={token}
        />
      )}
    </>
  )
}
