import React from 'react'
import { Box, Chip, Divider, Typography } from '@mui/material'
import { useParams, Link } from 'react-router-dom'
import {
  Breadcrumb,
  Container,
  GenericCardContainer,
  Loading,
  NoDataFoundMessage,
  UnexpectedErrorMessage
} from 'components'
import { chipColor, chipLabel } from '../../shared/subsidyUtils'
import theme from 'theme'
import { RequestStatus, useGetSubsidyRequests, getSessionUser, useGetDealers } from 'hooks'
import { ROUTE_PATHS } from 'constants/Routing'
import VehicleCard from '../../Vehicles/VehicleCard'
import { ReviewWorkflowDetails } from './ReviewWorkflowDetails'
import { LinearReview } from './LinearReview'
import { RenderData, renderRegion } from 'utils'
import { ROLES } from 'constants/Roles'
import { CustomUserClaim } from '@okta/okta-auth-js'

export function SubsidyRequestDetails() {
  const { id } = useParams()
  const user = getSessionUser()

  const { data, isLoading, isError, refetch, isRefetching } = useGetSubsidyRequests({ id })

  const request = data?.data[0]

  const {
    data: dealerData,
    isLoading: dealerLoading,
    isError: dealerError
  } = useGetDealers({ dealerCode: request?.dealerCode })

  const dealer = dealerData?.foi && dealerData?.foi[0]

  const sentStatus: RequestStatus = request?.requestStatuses?.find(
    ({ status }) => status === 'pending'
  )

  const managerStatus: RequestStatus = request?.requestStatuses?.find(
    ({ status }) => status === 'managerApproved' || status === 'managerDenied'
  )

  const adminStatus: RequestStatus = request?.requestStatuses?.find(
    ({ status }) => status === 'adminApproved' || status === 'adminDenied'
  )

  const bypassStatus: RequestStatus = request?.requestStatuses?.find(
    ({ status }) => status === 'adminByPassed'
  )

  function getLatestStatus(
    admin: RequestStatus,
    manager: RequestStatus,
    dealer: RequestStatus,
    bypass: RequestStatus,
    userRole: string | CustomUserClaim | CustomUserClaim[]
  ) {
    if (admin) return admin?.status

    if (userRole !== ROLES.DEALER && bypass) return bypass?.status

    if (manager) return manager?.status

    return dealer?.status
  }

  function renderVehicles(type: string) {
    switch (type) {
      case 'addition':
        return (
          <VehicleCard
            imageDistributionUrl={data?.imageDistributionUrl}
            vehicle={request?.mainVehicle}
            title="New Vehicle"
          />
        )
      case 'removal':
        return (
          <VehicleCard
            imageDistributionUrl={data?.imageDistributionUrl}
            vehicle={request?.mainVehicle}
            title="Vehicle"
          />
        )
      case 'replacement':
        return (
          <>
            <VehicleCard
              imageDistributionUrl={data?.imageDistributionUrl}
              vehicle={request?.mainVehicle}
              title="Current Vehicle"
            />
            <VehicleCard
              imageDistributionUrl={data?.imageDistributionUrl}
              vehicle={request?.secondaryVehicle}
              title="New Vehicle"
            />
          </>
        )
      case 'renewal':
        return (
          <VehicleCard
            imageDistributionUrl={data?.imageDistributionUrl}
            vehicle={request?.mainVehicle}
            title="Current Vehicle"
          />
        )
    }
  }

  const latestStatus = getLatestStatus(
    adminStatus,
    managerStatus,
    sentStatus,
    bypassStatus,
    user?.role
  )

  return (
    <Container
      fullWidth
      title={
        <Box>
          <Breadcrumb
            paths={[
              { label: 'Requests', path: ROUTE_PATHS.SUBSIDY_REQUESTS },
              {
                label: 'Request Details',
                path: ROUTE_PATHS.SUBSIDY_REQUEST_DETAILS
              }
            ]}
          />
          <Typography variant="h5" sx={{ mt: 2 }}>
            Request Details
          </Typography>
        </Box>
      }>
      {isLoading ? (
        <Loading
          sx={{
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.primary.light}`,
            margin: 0,
            padding: 30,
            marginBottom: theme.spacing(2)
          }}
        />
      ) : isError ? (
        <UnexpectedErrorMessage />
      ) : (
        <GenericCardContainer>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h6">Status:</Typography>
            <Chip
              sx={{
                borderRadius: 0.5,
                pb: 0,
                fontSize: 16,
                ...chipColor(latestStatus)
              }}
              label={chipLabel(latestStatus)}
            />
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box display="flex" alignItems="end" gap={1}>
            <Typography variant="h6">Request Type:</Typography>
            <Typography variant="body1" fontSize={18}>
              Vehicle {request.type.toUpperCase()}
            </Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box display="flex" alignItems="end" gap={1}>
            <Typography variant="h6">Request Comments:</Typography>
            <Typography variant="body1" fontSize={18}>
              {request.comments}
            </Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
          <LinearReview statuses={request.requestStatuses} userRole={user?.role} />
          <Divider sx={{ my: 2 }} />
          <Typography mb={1} variant="h6">
            Dealer Details:
          </Typography>
          <RenderData
            data={dealerData?.foi}
            loading={dealerLoading}
            error={dealerError}
            ErrorState={<UnexpectedErrorMessage />}
            LoadingState={<Loading sx={{ py: 5 }} />}
            EmptyState={<NoDataFoundMessage />}
            SuccessState={
              <Box display="flex" width="100%" gap={10}>
                <Box flexDirection="column" display="flex" gap={1.5}>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">Name:</Typography>
                    <Link
                      style={{ color: 'inherit' }}
                      to={
                        user?.role === ROLES.DEALER
                          ? ROUTE_PATHS.PROFILE
                          : ROUTE_PATHS.DEALER_DETAILS.replace(':id', dealer?.id)
                      }>
                      <Typography
                        sx={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          ':hover': { color: 'black' }
                        }}>
                        {dealer?.name}
                      </Typography>
                    </Link>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">Dealer Code:</Typography>
                    <Typography>{dealer?.id}</Typography>
                  </Box>
                </Box>
                <Box flexDirection="column" display="flex" gap={1}>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">Region:</Typography>
                    <Typography>{renderRegion(dealer?.divisionCode)}</Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">District:</Typography>
                    <Typography>{dealer?.districtID}</Typography>
                  </Box>
                </Box>
                <Box flexDirection="column" display="flex" gap={1}>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">Status:</Typography>
                    <Typography>{dealer?.dls.isEnrolled ? 'Active' : 'Inactive'}</Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <Typography fontWeight="bold">Wireless:</Typography>
                    <Typography>{dealer?.dls.wireless ? 'Yes' : 'No'}</Typography>
                  </Box>
                </Box>
              </Box>
            }
          />
          <Divider sx={{ mt: 2, mb: 3 }} />
          <Typography variant="h6">Vehicles Details:</Typography>
          <Box display="flex" gap={5}>
            {renderVehicles(request.type)}
          </Box>

          <Divider sx={{ mt: 2, mb: 3 }} />

          <ReviewWorkflowDetails
            user={user?.role}
            statuses={request.requestStatuses}
            refetch={refetch}
            isRefetching={isRefetching}
          />
        </GenericCardContainer>
      )}
    </Container>
  )
}
