import { AccessTimeFilled, CancelRounded, CheckCircle, LockClockRounded } from '@mui/icons-material'
import { Box, LinearProgress, Typography } from '@mui/material'
import { CustomUserClaim } from '@okta/okta-auth-js'
import { ROLES } from 'constants/Roles'
import { RequestStatus } from 'hooks'
import React from 'react'
import { formatTime } from 'utils'

interface StatusLabels {
  title: string
  subtitle: string
}

function progressBarValue(
  sentStatus: RequestStatus,
  managerStatus: RequestStatus,
  adminStatus: RequestStatus,
  bypassStatus: RequestStatus,
  userRole: string | CustomUserClaim | CustomUserClaim[]
) {
  if (adminStatus) return 100

  if (userRole === ROLES.DEALER) {
    if (managerStatus) return 50

    if (sentStatus) return 25

    return 0
  }

  if (bypassStatus) return 63

  if (managerStatus) return 38

  if (sentStatus) return 19

  return 0
}

function renderStatusIcons(
  managerStatus: string,
  adminStatus: string,
  bypassStatus: string,
  userRole: string | CustomUserClaim | CustomUserClaim[]
) {
  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="start">
        <CheckCircle />
      </Box>

      {userRole !== ROLES.DEALER && (
        <Box display="flex" flexDirection="column" alignItems="center">
          {managerStatus ? (
            managerStatus === 'managerApproved' ? (
              <CheckCircle />
            ) : (
              <CancelRounded />
            )
          ) : bypassStatus ? (
            <LockClockRounded />
          ) : (
            <AccessTimeFilled />
          )}
        </Box>
      )}

      <Box display="flex" flexDirection="column" alignItems="center">
        {bypassStatus ? <CheckCircle /> : <AccessTimeFilled />}
      </Box>

      <Box display="flex" flexDirection="column" alignItems="end">
        {adminStatus ? (
          adminStatus === 'adminApproved' ? (
            <CheckCircle />
          ) : (
            <CancelRounded />
          )
        ) : managerStatus === 'managerDenied' ? (
          <CancelRounded />
        ) : !managerStatus && !bypassStatus ? (
          <LockClockRounded />
        ) : (
          <AccessTimeFilled />
        )}
      </Box>
    </>
  )
}

function getManagerLabels(
  managerStatus: RequestStatus,
  adminStatus: RequestStatus,
  bypassStatus: RequestStatus,
  userRole: string | CustomUserClaim | CustomUserClaim[]
): StatusLabels {
  if (managerStatus) {
    if (managerStatus.status === 'managerApproved')
      return {
        title: 'Manager Approved',
        subtitle: `Approved on ${formatTime(managerStatus.statusTime)}`
      }
    else
      return {
        title: 'Manager Denied',
        subtitle: `Denied on ${formatTime(managerStatus.statusTime)}`
      }
  }

  if (userRole !== ROLES.DEALER && (bypassStatus || adminStatus)) {
    return {
      title: 'Manager Reviewing',
      subtitle: 'Admin Bypassed'
    }
  }

  return {
    title: 'Manager Reviewing',
    subtitle: 'Waiting Review'
  }
}

function getAdminLabels(
  managerStatus: RequestStatus,
  adminStatus: RequestStatus,
  bypassStatus: RequestStatus,
  userRole: string | CustomUserClaim | CustomUserClaim[]
): StatusLabels {
  if (adminStatus) {
    if (adminStatus.status === 'adminApproved')
      return {
        title: 'Admin Approved',
        subtitle: `Approved on ${formatTime(adminStatus.statusTime)}`
      }
    else
      return {
        title: 'Admin Denied',
        subtitle: `Denied on ${formatTime(adminStatus.statusTime)}`
      }
  }

  if (managerStatus) {
    if (managerStatus.status === 'managerDenied')
      return {
        title: 'Admin Blocked',
        subtitle: 'Admin blocked by Manager denial'
      }
    else
      return {
        title: 'Admin Reviewing',
        subtitle: 'Waiting Review'
      }
  }

  if (userRole !== ROLES.DEALER && bypassStatus) {
    return {
      title: 'Admin Reviewing',
      subtitle: 'Waiting Review'
    }
  }

  return {
    title: 'Admin Waiting',
    subtitle: 'Waiting Manager Review'
  }
}

function renderStatusLabels(
  sentStatus: RequestStatus,
  managerStatus: RequestStatus,
  adminStatus: RequestStatus,
  bypassStatus: RequestStatus,
  userRole: string | CustomUserClaim | CustomUserClaim[]
) {
  const { title: managerStepTitle, subtitle: managerStepSubtitle } = getManagerLabels(
    managerStatus,
    adminStatus,
    bypassStatus,
    userRole
  )

  const { title: adminStepTitle, subtitle: adminStepSubtitle } = getAdminLabels(
    managerStatus,
    adminStatus,
    bypassStatus,
    userRole
  )

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(${userRole === ROLES.DEALER ? '3' : '4'}, 1fr)`
      }}
      width="100%">
      <Box display="flex" flexDirection="column" alignItems="start">
        <Typography variant="body1" fontWeight="bold">
          Request Sent
        </Typography>
        <Typography variant="body2">Sent on {formatTime(sentStatus.statusTime)}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="body1" fontWeight="bold">
          {managerStepTitle}
        </Typography>
        <Typography variant="body2">{managerStepSubtitle}</Typography>
      </Box>
      {userRole !== ROLES.DEALER && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body1" fontWeight="bold">
            {'Admin Bypass'}
          </Typography>
          <Typography variant="body2">
            {!!bypassStatus && `Bypassed on ${formatTime(bypassStatus.statusTime)}`}
          </Typography>
        </Box>
      )}
      <Box display="flex" flexDirection="column" alignItems="end">
        <Typography variant="body1" fontWeight="bold">
          {adminStepTitle}
        </Typography>
        <Typography variant="body2">{adminStepSubtitle}</Typography>
      </Box>
    </Box>
  )
}

interface StatusProp {
  statuses: RequestStatus[]
  userRole: string | CustomUserClaim | CustomUserClaim[]
}

export function LinearReview({ statuses, userRole }: StatusProp) {
  const sentStatus = statuses.find(({ status }) => status === 'pending')

  const managerStatus = statuses.find(
    ({ status }) => status === 'managerApproved' || status === 'managerDenied'
  )

  const adminStatus = statuses.find(
    ({ status }) => status === 'adminApproved' || status === 'adminDenied'
  )

  const bypassStatus = statuses.find(({ status }) => status === 'adminByPassed')

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box display="flex" alignItems="end" gap={1}>
        <Typography variant="h6">Review Workflow:</Typography>
        <Typography variant="body1" fontSize={18}>
          The request is successful after getting Admin approval.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(${userRole === ROLES.DEALER ? '3' : '4'}, 1fr)`
          }}
          width="100%">
          {renderStatusIcons(
            managerStatus?.status,
            adminStatus?.status,
            bypassStatus?.status,
            userRole
          )}
        </Box>
        <LinearProgress
          sx={{ height: 7, borderRadius: 2 }}
          color="inherit"
          variant="determinate"
          value={progressBarValue(sentStatus, managerStatus, adminStatus, bypassStatus, userRole)}
        />
        <Box>
          {renderStatusLabels(sentStatus, managerStatus, adminStatus, bypassStatus, userRole)}
        </Box>
      </Box>
    </Box>
  )
}
